

/**
 * 当选择下拉选框的时候，查询该下拉选框的所有播放地址集合
 * @param state
 * @returns {*[]}
 */
export const getSpecificVideoPlayUrlList = (state) => {
  return function (a){
    //console.log("传入参数：" + a);
    if(!a){
      return null;
    }
    let linePlayDetailMap = state.videoLineM3u8UrlMap;
    let playUrlList = linePlayDetailMap[a];
    //console.log("总的数据：%O", linePlayDetailMap);
    //console.log("播放线路：", playUrlList);
    return playUrlList;
  }

}
